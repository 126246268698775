@import '../../variables.scss';

.projects-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        padding: 10px;
    }
}

.all-projects-container {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
}

.project-container {
    width: 290px;
    // height: auto;
    // margin: 15px;
    background: rgba(85, 79, 79, 0.58);
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    border-radius: 17px;
    cursor: pointer;
    transition: all 0.5s;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
        margin: 10px auto;
        max-width: 300px;
        height: auto;
    }
}

.project-container:hover {
    border: 1px solid $white-color;
    transform: scale(1.05);
}

.project-container:active {
    transform: scale(0.95);
}

.project-img-container {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
}

.project-img {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    opacity: 1;
    border: 1.5px solid $silver-color;

    @media (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
}

.project-subtitle {
    margin: 5px;
    color: $silver-color;
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 16px;
    }
}

.project-para {
    width: 280px;
    height: auto; // Remove fixed height for better flexibility
    justify-content: center;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    color: $white-color;

    @media (max-width: 768px) {
        width: 100%;
        font-size: 14px;
    }
}

.btn-container {
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.btn {
    font-weight: 600;
    padding: 15px;
    width: 120px;
    border-radius: 32px;
    color: $blue-color;
    border-color: $silver-color;
    margin-right: 10px;

    @media (max-width: 768px) {
        padding: 10px;
        width: 100px;
        margin: 5px 0;
        font-size: 12px;
    }
}

.btn-color-1, .btn-color-2 {
    border: $silver-color 1.6px solid;
}

.btn-color-1:hover, .btn-color-2:hover {
    cursor: pointer;
    background: $blue-color;
    color: $white-color;
}

.card {
    width: 290px;
    height: 330px;
    background: rgba(85, 79, 79, 0.58);
    border-radius: 17px;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    transition: all 0.4s;
    overflow: hidden;
    position: relative;

    &:hover {
        // transform: scale(1.05);
        background: rgba(85, 79, 79, 0.75);
    }

    &:hover .first-content {
        opacity: 0;
        height: 0;
    }

    &:hover .second-content {
        opacity: 1;
        height: 100%;
        transform: rotate(0deg);
    }
}

.first-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.second-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: rotate(-90deg);
    transition: all 0.4s ease-in-out;
    background: rgba(85, 79, 79, 0.9);
    color: $white-color;
}

.tech-stack-title {
    font-size: 1.5rem;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
}

.tech-stack-list {
    list-style: none;
    padding: 0;

    .tech-stack-item {
        font-size: 1rem;
        margin: 5px 0;
        color: $silver-color;

        @media (max-width: 768px) {
            font-size: 0.9rem;
        }
    }
}