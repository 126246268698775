.menu-content {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 110px);
}
  
.section-container {
    // height: 100vh;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
}
  