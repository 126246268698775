* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    background: transparent;
    font-family: 'Poiret One', sans-serif;
}
  
body {
    background: rgb(30, 30, 30);
    color: white;
    overflow: hidden;
    height: 100%;
    width: 100%;    
}

html {
    scroll-behavior: smooth;
    height: 100%;
    width: 100%;
}
.root {
    height: 100%;
    width: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(30, 30, 30);;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}